import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

class BlockIconAndText extends React.Component {
  constructor(props) {
    super(props)
    console.log(props)
  }

  render() {
    const d = this.props.data

    let style = {width:'130px', height:'130px'};

    if (d.smallIcon) {
      style = {width:'80px', height:'80px'};
    }

    let width = 'third-width-block';

    if (d.widthInsteadOfNormal33Width) {
      width = 'half-width-block';
    }

    let alignClass = 'text-center';

    if (d.align === 'Left') {
      alignClass = 'text-left'
    }

    if (d.align === 'Right') {
      alignClass = 'text-right'
    }

    return (
      <div id={d.jumpLinkId} className={`${alignClass} ${width} text-and-icon-block px-4 px-md-5 py-2 mb-4`}>
       
            <img
              style={style}
              className="mb-2"
              alt=""
              src={`${d.icon && d.icon.fields.file.url}?w=260&q=70`} />
         
        {!!d.text && (
          <div className="rich-text mt-2">
            {documentToReactComponents(d.text)}
          </div>
        )}
      </div>
    )
  }
}

export default BlockIconAndText
