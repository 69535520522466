import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

class Button extends React.Component {
  constructor(props) {
    super(props)
    console.log(props)
  }

  render() {
    const b = this.props.data

    return (
      <a
        className={`btn btn-content ml-0 mr-2 my-2 btn-cons--${
          b.colour ? b.colour.replace(' ', '').toLowerCase() : ''
        }`}
        href={b.url.replace('https://cleanairdev.co.uk','')}
        target={b.openInNewWindow ? '_blank' : ''}
      >
        {b.showPdfIcon ? (
          <FontAwesomeIcon className="mr-2" icon={faFilePdf} />
        ) : null}
        {b.title}
      </a>
    )
  }
}

export default Button
