import React from 'react'
import Container from 'reactstrap/lib/Container';

class BlockBackground extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const d = this.props.data
    let color = '#F1F4F7';
    let textColor= '';
    let classColor = '';
    let mW=''
    let bR=''

    if (d.boxMode) {
      mW='1240px'
      bR='10px'
    }

    if (d.colour === 'Dark blue') {
      color = '#21344C';
      textColor= '#ffffff';
      classColor='darkblue'
     
    }

    if (d.colour === 'Light blue') {
      color = '#F1F4F7';
      classColor='lightblue'
     
    }

    return (
      <div className={` ${classColor} w-100 component-holder`} style={{backgroundColor:color, color:textColor, maxWidth:mW, borderRadius:bR}}>{this.props.children}</div>
    )
  }
}

export default BlockBackground
