import React, { useEffect, useState } from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import Button from './Button'
import Container from 'reactstrap/lib/Container'
import { Link } from 'gatsby'
import { Col, Row } from 'reactstrap'
import NewsBlocks from './news-blocks'
import NewsBlocksJson from './news-blocks-json'

const BlockHomePageBoxes = props => {
  const d = props.data

  console.log('hpb', d)

  return (
    <Container className="boxes mt-5 mb-5">
      <Row>
        {d.boxes.map((b, i) => (
          <Col className="mb-4 mb-lg-0" key={`box${i}`} xs={6} sm={6} lg={3}>
            <Link className="boxes__box" to={b.fields.link}>
              <div className="boxes__box__image">
                <img
                  className="img-fluid"
                  style={{ objectFit: 'cover', width: '100%', height: '200px' }}
                  alt={b.fields.background.fields.title}
                  src={`${b.fields.background &&
                    b.fields.background.fields.file.url}?w=500&q=70`}
                />
              </div>
              <div className="boxes__box__text">
                <span>{b.fields.name}</span>
              </div>
            </Link>
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default BlockHomePageBoxes
