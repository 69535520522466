import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import Button from './Button'
import BlockText from './block-text'

class BlockImage extends React.Component {
  constructor(props) {
    super(props)
    console.log(props)
  }
 


  render() {
    const d = this.props.data

    let width = 'half-width-block';
    if (d.show33WidthInsteadOf50) {
      width = 'third-width-block text-center';
    }

     
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        console.log(node);
        switch (node.data.target.sys.contentType.sys.id) {
          case 'button':
            return <Button data={node.data.target.fields} />
          case 'componentText':
            return <BlockText noPadding data={node.data.target.fields} />
          default:
            break
        }
      },
    },
    renderText: text => {
      text = text.replace(/O2/g, 'O₂')
      return text
    },
  }

    return (
      <div id={d.jumpLinkId} className={`${width} p-4 p-md-5 mb-4`}>
        {d && !!d.imageLink && (
          <a href={d.imageLink}>
            <img
              className="border-radius-10 img-fluid"
              alt=""
              src={`${d.image && d.image.fields.file.url}?w=1000&q=70`}
            />
          </a>
        )}
        {d && !d.imageLink && (
          <img
            className="border-radius-10 img-fluid"
            alt=""
            src={`${d.image && d.image.fields.file.url}?w=1000&q=70`}
          />
        )}
        {!!d.text && (
          <div className="rich-text mt-3">
            {documentToReactComponents(d.text, options)}
          </div>
        )}
      </div>
    )
  }
}

export default BlockImage
