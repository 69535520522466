import React from 'react'
import Collapse from 'reactstrap/lib/Collapse'
import Container from 'reactstrap/lib/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types'
import Button from './Button'

class BlockAccordion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      accordionItemsOn: [],
    }
    this.toggleAccordion = this.toggleAccordion.bind(this)
  }

  toggleAccordion(item) {
    let accordionItemsOn = this.state.accordionItemsOn

    if (accordionItemsOn.includes(item)) {
      const index = accordionItemsOn.indexOf(item)
      accordionItemsOn.splice(index, 1)
    } else {
      accordionItemsOn.push(item)
    }
    this.setState({ accordionItemsOn })
  }

  render() {
    const d = this.props.data

    let bg = 'mythbusting__item--blueygrey';

    if (d.backgroundColour) {

      if (d.backgroundColour === 'White') {
        bg = 'mythbusting__item--white';

      }
      if (d.backgroundColour === 'Grey') {
        bg = 'mythbusting__item--grey';

      }

    }

    const options = {
      renderNode: {
        [BLOCKS.EMBEDDED_ENTRY]: node => {
          switch (node.data.target.sys.contentType.sys.id) {
            case 'button':
              return <Button data={node.data.target.fields} />

            default:
              break
          }
        },
      },
      renderText: text => {
        text = text.replace(/O2/g, 'O₂')
        text = text.split('\n').flatMap((text, i) => [i > 0 && <br />, text])
        return text
      },
    }

    return (
      <div id={d.jumpLinkId} className="full-width-block">
        <Container>
          {d.items.map((m, i) => (
            <div key={`accordion${i}`} className={`mythbusting__item ${bg}`}>
              <button
                style={{backgroundColor:m.fields.headerColourHex || '#32748B'}}
                className={`mythbusting__button ${
                  this.state.accordionItemsOn.includes('toggler' + i)
                    ? 'on'
                    : 'off'
                }`}
                id={`toggler${i}`}
                onClick={() => {
                  this.toggleAccordion(`toggler${i}`)
                }}
              >
                {m.fields.title}{' '}
                <FontAwesomeIcon
                  className={`chevron  ${
                    this.state.accordionItemsOn.includes('toggler' + i)
                      ? 'on'
                      : 'off'
                  }`}
                  icon={faChevronDown}
                />
              </button>
              <Collapse
                className="p-4 p-lg-5"
                isOpen={this.state.accordionItemsOn.includes('toggler' + i)}
              >
               <div>
                <div className="rich-text">{documentToReactComponents(m.fields.text, options)}</div>

               </div>
              </Collapse>
            </div>
          ))}
        </Container>
      </div>
    )
  }
}

export default BlockAccordion
