import React, { useEffect, useState } from 'react'
import Img from 'gatsby-image'
import { truncate, stripHtml } from '../helpers/helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTwitter,
  faFacebookF,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons'
import {
  CardColumns,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardText,
  CardSubtitle,
} from 'reactstrap'
import { Link as GatsbyLink } from 'gatsby'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Masonry from 'react-masonry-css'
const moment = require('moment')

const Link = ({
  children,
  to,
  className,
  activeClassName,
  partiallyActive,
}) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to)
  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink to={to} className={className}>
        {children}
      </GatsbyLink>
    )
  }
  return (
    <a href={to} target="_blank" className={className}>
      {children}
    </a>
  )
}

const NewsBlocksJson = () => {
  const [allItems, setAllItems] = useState([])

  useEffect(() => {
    fetch('https://cleanairgm.com/.netlify/functions/getNews')
      .then(data => data.json())
      .then(res => {

        const items = res.news
        // let tweets = res.tweets
        // tweets = tweets.filter(t => !t.fields.body.includes('RT '))
        // let newsItems = []
        // items.map((n, i) => {
        //   newsItems.push(n)
        //   if (i % 3 === 0 || i % 5 === 0) {
        //     if (tweets.length > 0) {
        //       newsItems.push(tweets.shift())
        //     }
        //   }
        // })
        setAllItems(items)
      })
  }, [])

  const breakpointColumnsObj = {
    default: 4,
    992: 4,
    576: 2,
    400: 2,
  }

  return (
    <div>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {allItems.map((node, i) => {
          let link = null

          switch (node.fields.type) {
            case 'News':
              link = `/news/${node.fields.slug}`
              break
            case 'Twitter':
              link = node.fields.externalUrl
              break
          }

          return (
            <div key={`nb${i}`}>
              <Link
                className="news-item-link-wrapper"
                aria-label="Read news article"
                to={link}
              >
                <Card
                  className={`news-item news-item--${node.fields.type.toLowerCase()} news-item--bg${i %
                    5}`}
                >
                  {node.fields.heroImage !== null && (
                    <img
                      className="border-radius-10 img-fluid"
                      alt=""
                      src={`${node.fields.heroImage &&
                        node.fields.heroImage.fields.file.url}?w=1000&q=70`}
                    />
                  )}
                  <CardBody>
                    <span className="news-item__date">
                      {moment(node.fields.publishDate).format('MMMM Do, YYYY')}
                    </span>

                    {node.fields.body !== null && node.fields.type !== 'News' && (
                      <CardTitle>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: truncate(stripHtml(node.fields.body), 50),
                          }}
                        ></span>
                      </CardTitle>
                    )}
                    {node.fields.title !== null &&
                      node.fields.type === 'News' && (
                        <CardTitle>{truncate(node.fields.title)}</CardTitle>
                      )}
                    <CardText>
                      {node.fields.type === 'News' && (
                        <span>
                          Read more{' '}
                          <FontAwesomeIcon
                            className="small-chevron"
                            icon={faChevronRight}
                          />
                        </span>
                      )}

                      {node.fields.type === 'Twitter' && (
                        <span>
                          <FontAwesomeIcon icon={faTwitter} /> @CleanAirGM
                        </span>
                      )}
                      {node.fields.type === 'Facebook' && (
                        <span>
                          <FontAwesomeIcon icon={faFacebookF} /> @CleanAirGM
                        </span>
                      )}
                      {node.fields.type === 'Instagram' && (
                        <span>
                          <FontAwesomeIcon icon={faInstagram} /> @CleanAirGM
                        </span>
                      )}
                    </CardText>
                  </CardBody>
                </Card>
              </Link>
            </div>
          )
        })}
      </Masonry>
    </div>
  )
}

export default NewsBlocksJson
