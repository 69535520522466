import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import Button from './Button'
import Container from 'reactstrap/lib/Container'
import Slider from 'react-slick'
import { Link } from 'gatsby'
import qe2 from '../images/EIIR-Header-1920x1080.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'

class BlockHomePageBanner extends React.Component {
  constructor(props) {
    super(props)
    console.log(props)
  }

  render() {
    const d = this.props.data

    const options = {
      renderNode: {
        [BLOCKS.EMBEDDED_ENTRY]: node => {
          switch (node.data.target.sys.contentType.sys.id) {
            case 'button':
              return <Button data={node.data.target.fields} />

            default:
              break
          }
        },
      },
      renderText: text => {
        text = text.replace(/O2/g, 'O₂')
        text = text.split('\n').flatMap((text, i) => [i > 0 && <br />, text])

        return text
      },
    }

    const SlickButtonFix = ({
      currentSlide,
      slideCount,
      children,
      ...props
    }) => <span {...props}>{children}</span>

    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      autoplaySpeed: 5000,
      autoplay: d.autoPlay || false,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: (
        <SlickButtonFix>
          <button type="button" aria-label="Previous" text="Previous">
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
        </SlickButtonFix>
      ),
      nextArrow: (
        <SlickButtonFix>
          <button type="button" aria-label="Next" text="Next">
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </SlickButtonFix>
      ),
    }

    return (
      <div className="full-width-block">
        <div className="header-slider">
         
          <Slider {...settings}>
            {d.slides.filter((s) => s.fields !== undefined).map((slide, i) => (
              <div
                className={`header-slider__slide header-slider__slide--${i}`}
              >
                <Container className="text-center text-md-left">
                  <div className="rich-text mt-3">
                    {documentToReactComponents(slide.fields.text, options)}
                  </div>
                  <div class="rhs text-right">
                    <img
                      alt={slide.fields.image.fields.description || slide.fields.image.fields.title}
                      src={`${slide.fields.image && slide.fields.image.fields && slide.fields.image.fields.file && slide.fields.image.fields.file.url}?w=600&q=70`}
                    />
                  </div>
                </Container>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    )
  }
}

export default BlockHomePageBanner
