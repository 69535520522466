import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import React from 'react'
import Container from 'reactstrap/lib/Container'
import header from '../images/CleanAir-Desktop-Header-1920x340-Curve-Disclaimer-2x.png'
import headerMobile from '../images/CleanAir-Mobile-Header-1080x550-Curve-Disclaimer-2x.png'
import { BLOCKS } from '@contentful/rich-text-types'
import Button from './Button'

class BlockHeader extends React.Component {
  constructor(props) {
    super(props)
    console.log(props)
  }

  render() {
    const d = this.props.data

    const options = {
      renderNode: {
        [BLOCKS.EMBEDDED_ENTRY]: node => {
          switch (node.data.target.sys.contentType.sys.id) {
            case 'button':
              return <Button data={node.data.target.fields} />

            default:
              break
          }
        },
      },
      renderText: text => {
        text = text.replace(/O2/g, 'O₂')
        return text
      },
    }

    let type = 'Image'
    if (d.type) {
      type = d.type
    }
    let alignClass = 'text-center'
    if (d.titleAlign === 'Left') {
      alignClass = 'text-lg-left text-center'
    }
    if (d.titleAlign === 'Right') {
      alignClass = 'text-lg-right text-center'
    }

    let backgroundColour = '#47BABD'
    if (d.backgroundColour === 'Light Green') {
      backgroundColour = '#47BABD'
    }
    if (d.backgroundColour === 'Green') {
      backgroundColour = '#009D8A'
    }
    if (d.backgroundColour === 'Blue') {
      backgroundColour = '#5395AD'
    }

    return (
      <div id={d.jumpLinkId} className="full-width-block">
        {type === 'Image' && (
          <div className="page-banner">
            <img
              className="page-banner__img d-none d-lg-block"
              alt=""
              src={`${d.desktopImage &&
                d.desktopImage.fields.file.url}?w=2000&q=70`}
            />
            <img
              className="page-banner__img d-block d-lg-none"
              alt=""
              src={`${(d.mobileImage && d.mobileImage.fields.file.url) ||
                (d.desktopImage &&
                  d.desktopImage.fields.file.url)}?w=1000&q=90`}
            />
            <Container>
              <h1 className={alignClass}>{d.title}</h1>
              {d.titleExtraLine && (
                <h1 className={alignClass}>{d.titleExtraLine}</h1>
              )}
            </Container>
          </div>
        )}

        {type === 'Cloud' && (
          <div className="page-banner page-banner--disclaimer">
            <img className="d-none d-lg-block" src={header} alt="" />
            <img className="d-block d-lg-none" src={headerMobile} alt="" />
            <Container>
              <h1 className={alignClass}>{d.title}</h1>
              {d.titleExtraLine && (
                <h1 className={alignClass}>{d.titleExtraLine}</h1>
              )}
            </Container>
          </div>
        )}

        {type === 'Plain' && (
          <div
            style={{ backgroundColor: backgroundColour }}
            className="page-banner page-banner--plain"
          >
            <Container>
              <h1 className={alignClass}>{d.title}</h1>
              {d.titleExtraLine && (
                <h1 className={alignClass}>{d.titleExtraLine}</h1>
              )}
              {!!d.content && (
                <div className="rich-text mt-2 text-white text-center">
                  {documentToReactComponents(d.content, options)}
                </div>
              )}
            </Container>
          </div>
        )}
      </div>
    )
  }
}

export default BlockHeader
