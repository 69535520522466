import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Container from 'reactstrap/lib/Container'

class BlockNeedMoreHelpFooter extends React.Component {
  constructor(props) {
    super(props)
    console.log(props)
  }

  render() {
    const d = this.props.data

    return (
      <div id={d.jumpLinkId} className="need-help-footer">
        <Container className="need-more-help-footer">
          <div className="rich-text mt-2 w-33">
            {documentToReactComponents(d.text)}
          </div>
          <div className="w-33 d-none md-d-block"></div>
          <div className="w-33">
            <img
              style={{ width: '111px' }}
              alt=""
              src={`${d.image && d.image.fields.file.url}?w=222&q=70`}
            />
          </div>
        </Container>
      </div>
    )
  }
}

export default BlockNeedMoreHelpFooter
