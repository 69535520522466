import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import Button from './Button'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

class BlockText extends React.Component {
  constructor(props) {
    super(props)
    console.log(props)
  }

  render() {
    const d = this.props.data
    const html = this.props.html || false
    const noPadding = this.props.noPadding || false

    let blockColorClass = ''

    if (d.backgroundColour === 'Green') {
      blockColorClass = 'green-box'
    }

    if (d.backgroundColour === 'Light Green') {
      blockColorClass = 'lightgreen-box'
    }

    if (d.backgroundColour === 'Light Blue') {
      blockColorClass = 'lightblue-box'
    }
    if (d.backgroundColour === 'Dark Blue') {
      blockColorClass = 'darkblue-box'
    }

    let padding = 'p-4 p-md-5'

    if (d.removeBottomPadding) {
      padding = 'pl-4 pr-4 pt-4 pr-md-5 pl-md-5 pt-md-5 pb-0'
    }
    if (d.removeTopPadding) {
      padding = 'pl-4 pr-4 pb-4 pr-md-5 pl-md-5 pb-md-5 pt-0'
    }
    if (d.removeTopPadding && d.removeBottomPadding) {
      padding = 'pl-4 pr-4 pr-md-5 pl-md-5 py-0'
    }

    if (noPadding) {
      padding = 'p-0 mt-4'
    }

    console.log(d)
    const options = {
      renderNode: {
        [BLOCKS.EMBEDDED_ENTRY]: node => {
          switch (node.data.target.sys.contentType.sys.id) {
            case 'button':
              return <Button data={node.data.target.fields} />

            default:
              break
          }
        },
        [INLINES.EMBEDDED_ENTRY]: node => {
          switch (node.data.target.sys.contentType.sys.id) {
            case 'warningIcon':
              if (
                ![
                  'Black line',
                  'Blue line',
                  'Red line',
                  'Amber line',
                  'Green line',
                  'Purple line',
                ].includes(node.data.target.fields.type)
              ) {
                return (
                  <FontAwesomeIcon
                    className="mr-3"
                    style={{
                      position: 'absolute',
                      left: '-40px',
                      fontSize: '20px',
                    }}
                    icon={faExclamationTriangle}
                  />
                )
              } else {
                let colorLine = '#000000'
                switch (node.data.target.fields.type) {
                  case 'Black line':
                    colorLine = '#000000'
                    break
                  case 'Blue line':
                    colorLine = '#4173AB'
                    break
                  case 'Red line':
                    colorLine = '#D41723'
                    break
                  case 'Amber line':
                    colorLine = '#FF7F00'
                    break
                  case 'Green line':
                    colorLine = '#8CC47D'
                    break
                  case 'Purple line':
                    colorLine = '#C388C0'
                    break

                  default:
                    break
                }

                return (
                  <div
                    style={{
                      position: 'absolute',
                      display: 'block',
                      left: '-10px',
                      marginTop: '7px',
                      width: '40px',
                      height: '10px',
                      backgroundColor: colorLine,
                    }}
                  ></div>
                )
              }

            default:
              break
          }
        },
      },
      renderText: text => {
        text = text.replace(/O2/g, 'O₂')
        text = text.split('\n').flatMap((text, i) => [i > 0 && <br />, text])
        return text
      },
    }

    let alignClass = 'text-left'

    if (d.align === 'Center') {
      alignClass = 'text-center'
    }

    if (d.align === 'Right') {
      alignClass = 'text-right'
    }

    let widthClass = `half-width-block  ${
      d.reducedWidth ? 'half-width-block--reduced' : ''
    }`

    if (d.fullWidth) {
      widthClass = 'full-width-block container'
    }

    return (
      <div
        id={d.jumpLinkId}
        className={`${alignClass} ${padding} ${widthClass} `}
      >
        <div className={`${blockColorClass}`}>
          <div
            className={`${
              d.showBulletsAsTicks ? 'tick-bullets' : ''
            } rich-text position-relative`}
          >
            {html && (
              <div
                dangerouslySetInnerHTML={{
                  __html: d.text,
                }}
              ></div>
            )}
            {!html && documentToReactComponents(d.text, options)}
          </div>
        </div>
      </div>
    )
  }
}

export default BlockText
