import React, { useEffect, useState } from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import Button from './Button'
import Container from 'reactstrap/lib/Container'
import { Link } from 'gatsby'
import { Col, Row } from 'reactstrap'
import NewsBlocks from './news-blocks'
import NewsBlocksJson from './news-blocks-json'
import DealershipsComponent from './dealerships-component'

const BlockIframe = props => {

  const d = props.data

  

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        switch (node.data.target.sys.contentType.sys.id) {
          case 'button':
            return <Button data={node.data.target.fields} />

          default:
            break
        }
      },
    },
    renderText: text => {
      text = text.replace(/O2/g, 'O₂')
      return text
    },
  }

  if (d.dealershipComponent === true) {
    return (
      <DealershipsComponent />
    )
  }

  if (d.newsBoxes === true) {
    return (
      <div style={{ width: '100%', marginBottom:'-40px', paddingBottom:'80px' }} className="news-box">
        <Container>
          <h1>All the latest news and updates</h1>
          <div className="news-box__items-holder">
            <NewsBlocksJson />
          </div>
          <Row>
            <Col className="text-center">
              <Link className="btn btn-content" to={'/news'}>
                Visit the news hub
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  return (
    <div id={d.jumpLinkId} className="full-width-block">
      <Container>
        <div className="text-center pb-0 block-title mt-4 mb-4">
          <iframe
            style={{ width: '100%', height: d.height + 'px', border: 'none' }}
            src={d.url}
          ></iframe>
          {documentToReactComponents(d.text, options)}
        </div>
      </Container>
    </div>
  )
}

export default BlockIframe
