import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import Container from 'reactstrap/lib/Container'

const BlockIncident = props => {
  const d = props.data

  return (
    <div
      style={{
        backgroundColor: '#E5B040',
        paddingTop: 40,
        paddingBottom: 20,
      }}
      className="full-width-block"
    >
      <Container>
        <div style={{ position: 'relative' }}>
          <FontAwesomeIcon
            className="d-none d-md-block"
            style={{ position: 'absolute', left: '-30px', top:'2px' }}
            icon={faExclamationCircle}
          />
           <FontAwesomeIcon
            className="d-md-none"
            icon={faExclamationCircle}
          />
          <div>{documentToReactComponents(d.content)}</div>
        </div>
      </Container>
    </div>
  )
}

export default BlockIncident
